import React from "react"
import Layout from "../layout";
import path from "path";
import Img from "gatsby-image";
import { navigate } from "gatsby";

class CollectionPhotoTemplate extends React.Component {
   constructor(props) {
      super(props);

      const nameToFluidPhoto = {};

      props.data.allMarkdownRemark.edges.forEach((edge) => {
         if (edge.node.fields) {
            const name = path.basename(edge.node.fileAbsolutePath)
            nameToFluidPhoto[name] = edge.node.fields.image.childImageSharp.fluid;
         }
      });

      this.state = {
         nameToFluidPhoto,
      };
   }

   onPhotoClicked() {
      const {order, name, galleryName} = this.props.pageContext;

      const currentIndex = order.indexOf(name);
      const newIndex = currentIndex === order.length - 1
                        ? 0
                        : currentIndex + 1;

      navigate(`/gallery/${galleryName}/${order[newIndex]}`);
   }

   render() {
      return (
         <Layout galleryLinksOpen={true}>
            <div className='single-photo-container' onClick={this.onPhotoClicked.bind(this)}>
               <Img
                  fluid={this.state.nameToFluidPhoto[this.props.pageContext.name]}
                  className='single-photo'
                  imgStyle={{objectFit: 'contain'}}
               />
            </div>
         </Layout>
      )
   }
}

export default CollectionPhotoTemplate;
