import React from "react"
import { StaticQuery, graphql } from "gatsby"
import CollectionPhotoTemplate from "./collection-photo-template";

export default (props) => (
   <StaticQuery
      query={graphql`
      query CollectionPhotoTemplateWrapper {
        allMarkdownRemark {
           edges {
              node {
                 fileAbsolutePath
                 frontmatter {
                     title
                     file
                   }
                   fields {
                        image {
                         childImageSharp {
                           fluid(maxWidth: 5000, quality: 100) {
                           # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                              ...GatsbyImageSharpFluid
                           }
                         }
                       }
                   }
                
              }
           }
        }   
      }
    `}
      render={data => (
         <CollectionPhotoTemplate data={data} pageContext={props.pageContext}/>
      )}
   />
)



